import { Badge } from 'reactstrap'
import { externalInboundInvoiceStatusMap, externalInvoiceStatusMap } from '@evelia/common/constants'
import { capitalize } from '@evelia/common/helpers'
import { InboundInvoiceModel } from '@evelia/common/types'

import { InvoiceModel } from '../../api/rtk/invoiceApi'
import { ellipsis } from '../../helpers/helpers'

interface ExternalStatusBadgeProps {
  externalData: InvoiceModel['externalData'] | InboundInvoiceModel['externalData']
  service?: string | null
  type: 'invoice' | 'inboundInvoice'
  truncate?: boolean
}

const ExternalStatusBadge = ({ externalData, service, type, truncate }: ExternalStatusBadgeProps) => {
  if(!service) {
    return null
  }

  const statusKey = `${service}Status`
  const status = externalData?.[statusKey]
  const serviceStatusMap = (type === 'invoice' ? externalInvoiceStatusMap[service] : externalInboundInvoiceStatusMap[service]) || null

  if(!serviceStatusMap) {
    return null
  }

  const badgeStatus = serviceStatusMap ? (serviceStatusMap[status] ?? '(Tuntematon tila)') : '(Tuntematon tila)'
  const truncatedBadgeStatus = truncate ? ellipsis(badgeStatus, 10, 5) : badgeStatus
  const serviceName = capitalize(service)
  const color = status === 'FAILED' ? 'danger' : 'info'

  return <Badge color={color} title={`${serviceName}: ${badgeStatus}`}>{serviceName}: {truncate ? truncatedBadgeStatus : badgeStatus}</Badge>
}

export default ExternalStatusBadge
