import { now } from '@evelia/common/dateHelpers'
import { BaseIdModel } from '@evelia/common/types'
import { Action, Reducer, UnknownAction } from '@reduxjs/toolkit'
import groupBy from 'lodash/groupBy'
import isObject from 'lodash/isObject.js'
import { combineReducers } from 'redux'
import reduxCrud from 'redux-crud-mb'
import merge from 'redux-crud-mb/dist/reducers/list/store/merge'

import { TableOptionsModel } from '../api/rtk'
import { NEW_ENTRY_ID } from '../constants'
import { ReducerMetadata, ReducerSearchData } from '../reducerTypes'
import { actionTypesFor } from './actionHelpers'
import { isNewlyCreated, textToBooleanMaybe } from './helpers'

export const getTableOptions = ({
  orderBy = [],
  filters = {},
  fixedFilters = {},
  limit,
  sortOrder = []
}: { orderBy?: string[], filters?: Record<string, unknown>, fixedFilters?: Record<string, unknown>, limit?: number, sortOrder?: ('ASC' | 'DESC')[] } = {}) => {
  return {
    sortOrder,
    orderBy,
    filters,
    fixedFilters,
    limit,
    page: 0,
    q: null
  }
}

const initialMetadataState: ReducerMetadata = {
  busy: true,
  lastFetched: null,
  fetchParams: {}
}

interface MetaDataAction extends Action {
  isBusy: boolean
  fetchParams: Record<string, unknown>
  data: {
    updateLastFetched: boolean
  }
}

type ActionStages = 'Request' | 'Start' | 'Success' | 'Error'
type ActionTypeKeys = 'fetch' | 'update' | 'create' | 'delete' | 'patch' | 'search'
type AllActionTypes = `${ActionTypeKeys}${ActionStages}` | 'setBusy' | 'setFetchParams' | 'editOpen' | 'editClose' | 'newInit'

type ActionTypes = Record<AllActionTypes, string>

const metaDataReducerFor = (actionTypes: ActionTypes) => {
  return (state: ReducerMetadata = initialMetadataState, action: MetaDataAction): ReducerMetadata => {
    const extraData = action.data || {}
    switch(action.type) {
      case actionTypes.setBusy:
        return {
          ...state,
          busy: action.isBusy
        }
      case actionTypes.setFetchParams:
        return {
          ...state,
          fetchParams: action.fetchParams
        }
      case actionTypes.fetchSuccess:
        return {
          ...state,
          busy: false,
          lastFetched: extraData.updateLastFetched ? now() : state.lastFetched
        }
      case actionTypes.fetchStart:
        return {
          ...state,
          busy: true
        }
      case actionTypes.fetchError:
        return {
          ...state,
          busy: false
        }
      default:
        return state
    }
  }
}

const initialSearchState: ReducerSearchData = {
  isBusy: false,
  searchResultIds: [],
  error: null,
  searchTerm: null
}

interface SearchAction<T extends BaseIdModel> {
  type: string
  searchTerm?: string
  result: T[]
  error?: string | null
}

const searchReducerFor = <T extends BaseIdModel>(actionTypes: ActionTypes): Reducer<ReducerSearchData, SearchAction<T>> => {
  return (state = initialSearchState, action) => {
    switch(action.type) {
      case actionTypes.searchStart:
        return {
          ...state,
          searchResultIds: [],
          isBusy: true,
          error: null
        }
      case actionTypes.searchSuccess:
        return {
          ...state,
          searchTerm: action.searchTerm as string,
          searchResultIds: action.result.map(result => result.id),
          isBusy: false
        }
      case actionTypes.searchError:
        return {
          ...state,
          searchResultIds: [],
          isBusy: false,
          error: action.error ?? null
        }
      default:
        return state
    }
  }
}

const clientReducersFor = <T extends BaseIdModel>(actionTypes: ActionTypes): Reducer<T[], Action & { record: T }> => {
  return (state = [], action) => {
    switch(action.type) {
      case actionTypes.editOpen:
        return merge(state, [{ ...action.record, _isEditable: true }], 'id')
      case actionTypes.editClose: {
        if(isNewlyCreated(action.record)) {
          return state.filter(record => record.id !== action.record.id)
        }
        const selected = state.find(record => record.id === action.record.id)
        return merge(state, [{ ...selected, _isEditable: false }], 'id')
      }
      case actionTypes.newInit:
        return [...state.filter(record => record.id !== NEW_ENTRY_ID), { ...action.record, _isEditable: true, id: NEW_ENTRY_ID }]
      default:
        return state
    }
  }
}

const combineReducersToReduxCrudReducers = <T extends BaseIdModel>(reducers: Reducer<T[], Action & { record: T }>, reduxCrudReducer: ReturnType<typeof reduxCrud.List.reducersFor>): Reducer<T[], Action & { record: T }> => (state, action) =>
  reducers(reduxCrudReducer(state, action), action)

const getCombinedListReducerFor = <T extends BaseIdModel>(name: string, additionalReducers = {}, key = 'id') => {
  const actionTypes = actionTypesFor(name) as ActionTypes
  const clientReducers = clientReducersFor(actionTypes)
  const recordReducers = combineReducersToReduxCrudReducers(clientReducers, reduxCrud.List.reducersFor(name, { key }))
  return combineReducers({
    records: recordReducers as Reducer<T[], UnknownAction>,
    metadata: metaDataReducerFor(actionTypes),
    search: searchReducerFor<T>(actionTypes),
    ...additionalReducers
  })
}

const getCombinedMapReducerFor = <T extends BaseIdModel>(name: string, additionalReducers = {}, key = 'id') => {
  const actionTypes = actionTypesFor(name) as ActionTypes
  return combineReducers({
    records: reduxCrud.Map.reducersFor(name, { key })as Reducer<Record<string, T>, UnknownAction>,
    metadata: metaDataReducerFor(actionTypes),
    search: searchReducerFor(actionTypes),
    ...additionalReducers
  })
}

const getCombinedSingleRecordReducerFor = (name: string, additionalReducers = {}, initialRecord = null) => {
  const actionTypes = actionTypesFor(name) as ActionTypes
  return combineReducers({
    record: (state: object | null = initialRecord, action) => {
      switch(action.type) {
        case actionTypes.fetchSuccess:
          return {
            ...state,
            ...action.records
          }
        case actionTypes.updateSuccess:
        case actionTypes.createSuccess:
          return {
            ...state,
            ...action.record
          }
        case actionTypes.deleteSuccess:
          return initialRecord
        default:
          return state
      }
    },
    metadata: metaDataReducerFor(actionTypes),
    ...additionalReducers
  })
}

const reduceFilter = (key: string, filter: string | { operator: string, value: string }, operator?: string) => {
  const acc: [key: string, value: string, operator?: string][] = []
  let value
  let filterOperator = operator
  if(isObject(filter) && filter.operator) {
    value = filter.value
    filterOperator = filter.operator
  } else {
    value = filter
  }
  if(Array.isArray(value)) {
    value.forEach(one => acc.push(...reduceFilter(key, one, filterOperator)))
  } else {
    acc.push([key, `${value}`, filterOperator])
  }
  return acc
}

const parseFiltersFromObject = (filters: Record<string, { operator: string, value: string }>) => {
  return Object.entries(filters).reduce((acc: [key: string, value: string, operator?: string][], [key, filter]) => {
    if(filter == null) {
      return acc
    }
    acc.push(...reduceFilter(key, filter))
    return acc
  }, [])
}

export const parseFilterParams = params => {
  if(!params || (!params.filters && !params.fixedFilters)) {
    return params
  }
  const { filters, fixedFilters, ...rest } = params
  const arrayFilters = parseFiltersFromObject({ ...filters, ...fixedFilters })
  return ({
    ...rest,
    filters: arrayFilters
  })
}

const convertToValue = ([__key, value, operator]) => {
  value = textToBooleanMaybe(value)
  if(operator != null) {
    return {
      value,
      operator
    }
  }
  return value
}

const parseObjectFromFilters = filters => {
  return Object.entries(groupBy(filters, '[0]')).reduce((acc, [key, values]) => {
    if(values.length === 1) {
      acc[key] = convertToValue(values[0])
    } else {
      acc[key] = values.map(convertToValue)
    }
    return acc
  }, {})
}

export const parseTableOptionsFromQuery = (options: TableOptionsModel | undefined): TableOptionsModel | null => {
  if(options == null) {
    return null
  }
  if(Array.isArray(options.filters)) {
    return { ...options, filters: parseObjectFromFilters(options.filters) }
  }
  return options
}

const getStatsReducer = (actions, initialStats) => {
  const initialState = {
    ...initialStats,
    isBusy: false,
    updatedAt: null
  }
  return (state = initialState, action) => {
    switch(action.type) {
      case actions.actionTypes.fetchStatsStart:
        return {
          ...state,
          isBusy: true
        }
      case actions.actionTypes.fetchStatsSuccess:
        return {
          ...state,
          ...action.record,
          isBusy: false,
          updatedAt: now()
        }
      case actions.actionTypes.fetchStatsError:
        return {
          ...initialState,
          isBusy: false
        }
      default:
        return state
    }
  }
}

const getPrhReducer = (actions, initialPrh) => {
  return (state = initialPrh, action) => {
    switch(action.type) {
      case actions.actionTypes.fetchPrhStart:
        return {
          ...initialPrh,
          isBusy: true
        }
      case actions.actionTypes.fetchPrhSuccess:
        return {
          ...state,
          ...action.record,
          isBusy: false
        }
      case actions.actionTypes.fetchPrhError:
        return {
          ...initialPrh,
          isBusy: false
        }
      default:
        return state
    }
  }
}

const getExtraInfoReducer = (actions, idField) => {
  return (state = [], action) => {
    switch(action.type) {
      case actions.actionTypes.fetchExtraInfoSuccess:
        return [
          ...filterOldRecordsBasedOnId(state, action.record, idField),
          ...action.record
        ]
      default:
        return state
    }
  }
}

const filterOldRecordsBasedOnId = (oldRecords, newRecords, idField) => {
  const freshRecordIds = new Set(newRecords.map(record => record[idField]))
  return oldRecords.filter(record => !freshRecordIds.has(record[idField]))
}

export {
  getCombinedListReducerFor,
  getCombinedMapReducerFor,
  getCombinedSingleRecordReducerFor,
  getStatsReducer,
  getPrhReducer,
  getExtraInfoReducer
}
