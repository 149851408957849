import { inboundInvoiceTypes } from '@evelia/common/constants'

import inboundInvoiceActions from '../actions/inboundInvoiceActions'
import constants, { accountingStatuses, inboundInvoiceStates } from '../constants'
import {
  getCombinedListReducerFor,
  getExtraInfoReducer,
  getStatsReducer,
  getTableOptions
} from '../helpers/reducerHelpers'
import tableReducerFor from './tableReducer'

const initialStats = {
  received: 0,
  approved: 0,
  waitingPaymentPermission: 0,
  rejected: 0,
  credit: 0,
  failed: 0,
  payments: 0,
  waitingApproval: 0
}

const filters = {
  includeArchived: false
}
const defaultFixedFilters = {
  type: [inboundInvoiceTypes.INBOUND_INVOICE_TYPE_INVOICE, inboundInvoiceTypes.INBOUND_INVOICE_TYPE_PAYMENT],
  isHold: false
}

export const initialInboundInvoiceTableOptions = {
  default: getTableOptions({ sortOrder: 'DESC', filters, fixedFilters: defaultFixedFilters }),
  received: getTableOptions({ sortOrder: 'DESC', filters, fixedFilters: { ...defaultFixedFilters, state: inboundInvoiceStates.UNAPPROVED_STATUSES } }),
  approved: getTableOptions({ sortOrder: 'DESC', filters, fixedFilters: { ...defaultFixedFilters, state: inboundInvoiceStates.INBOUND_INVOICE_STATE_APPROVED } }),
  waitingPayment: getTableOptions({ sortOrder: 'DESC', filters, fixedFilters: { ...defaultFixedFilters, paymentState: [...inboundInvoiceStates.PAID_STATUSES, inboundInvoiceStates.INBOUND_INVOICE_PAYMENT_STATE_WAITING, inboundInvoiceStates.INBOUND_INVOICE_PAYMENT_STATE_PARTIALLY_PAID], banksonState: [accountingStatuses.ACCOUNTING_STATUS_WAITING_PAYMENT, { value: null, operator: 'isNull' }] } }),
  paid: getTableOptions({ sortOrder: 'DESC', filters, fixedFilters: { ...defaultFixedFilters, paymentState: inboundInvoiceStates.PAID_STATUSES, banksonState: { value: accountingStatuses.ACCOUNTING_STATUS_WAITING_PAYMENT, operator: 'isDistinctFrom' } } }),
  rejected: getTableOptions({ sortOrder: 'DESC', filters, fixedFilters: { ...defaultFixedFilters, state: inboundInvoiceStates.INBOUND_INVOICE_STATE_REJECTED } }),
  onHold: getTableOptions({ sortOrder: 'DESC', filters, fixedFilters: { ...defaultFixedFilters, isHold: true } }),
  failed: getTableOptions({ sortOrder: 'DESC', filters, fixedFilters: { ...defaultFixedFilters, type: [...defaultFixedFilters.type, inboundInvoiceTypes.INBOUND_INVOICE_TYPE_SALARY], paymentState: inboundInvoiceStates.INBOUND_INVOICE_PAYMENT_STATE_FAILED } }),
  waitingApproval: getTableOptions({ sortOrder: 'DESC', filters, fixedFilters: { isHold: false, waitingApproval: true } }),
  waitingPaymentPermission: getTableOptions({ sortOrder: 'DESC', filters, fixedFilters: { isHold: false, type: [...defaultFixedFilters.type, inboundInvoiceTypes.INBOUND_INVOICE_TYPE_SALARY], state: inboundInvoiceStates.INBOUND_INVOICE_STATE_APPROVED, paymentState: inboundInvoiceStates.INBOUND_INVOICE_PAYMENT_STATE_WAITING_PERMISSION } }),
  credit: getTableOptions({ sortOrder: 'DESC', filters, fixedFilters: { type: inboundInvoiceTypes.INBOUND_INVOICE_TYPE_CREDIT } }),
  payments: getTableOptions({ sortOrder: 'DESC', filters, fixedFilters: { type: inboundInvoiceTypes.INBOUND_INVOICE_TYPE_PAYMENT } }),
  salaries: getTableOptions({ sortOrder: 'DESC', filters, fixedFilters: { type: inboundInvoiceTypes.INBOUND_INVOICE_TYPE_SALARY } }),
  recurringPayments: getTableOptions({ sortOrder: 'DESC', filters, fixedFilters: { type: inboundInvoiceTypes.INBOUND_INVOICE_TYPE_TEMPLATE } })
}

export default getCombinedListReducerFor(constants.actionKeys.INBOUND_INVOICES, {
  rows: getCombinedListReducerFor(constants.actionKeys.INBOUND_INVOICE_ROWS),
  files: getCombinedListReducerFor(constants.actionKeys.INBOUND_INVOICE_FILES),
  memos: getCombinedListReducerFor(constants.actionKeys.INBOUND_INVOICE_MEMOS),
  approvals: getCombinedListReducerFor(constants.actionKeys.INBOUND_INVOICE_APPROVALS),
  approvers: getCombinedListReducerFor(constants.actionKeys.INBOUND_INVOICE_APPROVERS),
  accountings: getCombinedListReducerFor(constants.actionKeys.INBOUND_INVOICE_ACCOUNTINGS),
  recurrenceRules: getCombinedListReducerFor(constants.actionKeys.INBOUND_INVOICE_RECURRENCE_RULES),
  recurrenceOccurrencies: getCombinedListReducerFor(constants.actionKeys.INBOUND_INVOICE_RECURRENCE_OCCURRENCIES),
  tableOptions: tableReducerFor(constants.actionKeys.INBOUND_INVOICES, initialInboundInvoiceTableOptions),
  stats: getStatsReducer(inboundInvoiceActions, initialStats),
  extraInfo: getExtraInfoReducer(inboundInvoiceActions, 'inboundInvoiceId'),
  statuses: getCombinedListReducerFor(constants.actionKeys.INBOUND_INVOICE_STATUSES)
})
