import { Link } from 'react-router-dom'
import { Badge } from 'reactstrap'
import clsx from 'clsx'

import { getUrl as getInvoiceUrl } from '../../actions/invoiceActions'
import { InvoiceModel } from '../../api/rtk/invoiceApi'
import ExternalStatusBadge from '../../components/Badge/ExternalStatusBadge'
import { getInternalStatus, getReminderState } from '../../selectors/invoiceSelectors'

const interestInvoiceState = {
  color: 'info',
  title: 'Korkolaskutettu'
}

interface BadgeProps {
  invoice: InvoiceModel
}

interface InvoiceStatusBadgeProps {
  invoice: InvoiceModel
  stacked?: boolean
  truncateExternalStatus?: boolean
}

const InterestInvoiceBadge = ({ invoice }: BadgeProps) => {
  if(!invoice.interestInvoiceId) {
    return null
  }
  return (
    <Link to={getInvoiceUrl(invoice.interestInvoiceId)}>
      <Badge color={interestInvoiceState.color}>{interestInvoiceState.title}</Badge>
    </Link>
  )
}

const ReminderBadge = ({ invoice }: BadgeProps) => {
  const reminderState = getReminderState(invoice)
  if(!reminderState) {
    return null
  }
  return (
    <Badge color={reminderState.color}>{reminderState.title}</Badge>
  )
}

const InvoiceStatusBadge = ({ invoice, stacked, truncateExternalStatus }: InvoiceStatusBadgeProps) => {
  if(!invoice) {
    return null
  }
  const { title, color } = getInternalStatus(invoice)

  return (
    <span className={clsx('d-inline-flex gap-1 flex-wrap mr-child-1', stacked && 'flex-column align-items-start')}>
      {!!invoice.archivedAt && <Badge color='info'>Arkistoitu</Badge>}
      <Badge color={color}>{title}</Badge>
      <ReminderBadge invoice={invoice} />
      <InterestInvoiceBadge invoice={invoice} />
      <ExternalStatusBadge externalData={invoice.externalData} service={invoice.externalData?.service} type='invoice' truncate={truncateExternalStatus} />
    </span>
  )
}

const getStatusText = (invoice: InvoiceModel) => getInternalStatus(invoice).title

export default InvoiceStatusBadge
export {
  getStatusText
}
