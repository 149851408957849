import { accessLevels, emptyArray, fileRelationTables, systemFileTags } from '@evelia/common/constants'
import { isDefined } from '@evelia/common/helpers'
import { FileModel } from '@evelia/common/types'
import { createCachedSelector } from 're-reselect'

import { AuthorModel } from '../api/rtk/authorApi'
import { EmployeeLevelModel } from '../api/rtk/employeeLevelsApi'
import { createEveliaSelector, getFindItemByIdSelector, GetListFn } from '../helpers/typedSelectorHelpers'
import { EveliaRootState } from '../reducerTypes'
import { findAuthorsOfCurrentEmployee } from './authorSelectors'
import { findCurrentEmployeeLevel, getEmployeeLevelsFromArgument } from './employeeSelectors'

const getFilesFromArgument: GetListFn<FileModel> = arg => Array.isArray(arg) ? arg : arg.files.records
const getFileLinkFromArgument = (arg, storeName) => Array.isArray(arg) ? arg : arg[storeName]?.files.records
const getToolkitFileLinkFromArgument = (arg, selector) => Array.isArray(arg) ? arg : selector(arg)

const findFileWithId = getFindItemByIdSelector(getFilesFromArgument)

const checkHasFileAccess = (file: FileModel, authors: AuthorModel[], currentEmployeeLevel: EmployeeLevelModel, employeeLevels: EmployeeLevelModel[]) => {
  if(!file) {
    return false
  }
  const authorIds = authors.map(author => author.id)
  const userAccessLevel = employeeLevels.find(employeeLevel => employeeLevel.accessLevel === accessLevels.USER)?.accessLevel
  const canSeeNonLimitedFiles = userAccessLevel && currentEmployeeLevel.accessLevel >= userAccessLevel
  const fileEmployeeLevel = employeeLevels.find(employeeLevel => employeeLevel.id === file.employeeLevelId)
  return authorIds.includes(file.createdBy) ||
    // Restricted users have only access to their own files and ones with provided accessLevel
    (fileEmployeeLevel && fileEmployeeLevel.accessLevel <= currentEmployeeLevel.accessLevel) ||
    (canSeeNonLimitedFiles ? !file.employeeLevelId : false)
}
export const getFiles = createEveliaSelector(
  [
    getFilesFromArgument,
    findAuthorsOfCurrentEmployee,
    findCurrentEmployeeLevel,
    getEmployeeLevelsFromArgument
  ],
  (files: FileModel[], authors, currentEmployeeLevel, employeeLevels) => {
    const filterFile = (file: FileModel) => checkHasFileAccess(file, authors, currentEmployeeLevel, employeeLevels)
    return files.filter(filterFile)
  }
)

const createFileRelationSelector = (relationName: string, overrideDescription: Record<string, string>) => {
  const relationPluralName = `${relationName}s`
  const relationIdName = `${relationName}Id`

  return createEveliaSelector(
    [
      state => state.files?.records || emptyArray,
      state => state[relationPluralName]?.files?.records || emptyArray,
      state => state.files[`${relationName}Files`]?.records || emptyArray,
      (__state, relationId: number | string) => Number(relationId),
      findAuthorsOfCurrentEmployee,
      findCurrentEmployeeLevel,
      getEmployeeLevelsFromArgument
    ],
    (files, relationFileRecords, relationRecords, relationId, authors: AuthorModel[], currentEmployeeLevel, employeeLevels) => {
      const { relationFiles } = relationRecords.find(relation => relation[relationIdName] === relationId) || {}
      if(!relationFiles) {
        return emptyArray
      }

      const filterFile = (file: FileModel) => checkHasFileAccess(file, authors, currentEmployeeLevel, employeeLevels)
      const filteredRelationFiles = relationFiles.map(relationFile => ({
        ...relationFile,
        description: overrideDescription[relationFile.type] ?? relationFile.description,
        children: relationFile.children.map(child => ({
          ...child,
          children: child.children.filter(filterFile)
        }))
      }))

      // Add the relations own files to the relationFiles array
      // This is combined here so that all relations own file add/deletion etc
      // are directly refreshed in the usage and no need to fetch all relation files again
      const relationFileIds = relationFileRecords.filter(file => file[relationIdName] === relationId).map(file => file.fileId)
      const crFilesIndex = filteredRelationFiles.findIndex(relationFile => relationFile.type === relationName)
      if(crFilesIndex < 0) {
        // Should not happen !!
        return emptyArray
      }

      filteredRelationFiles[crFilesIndex] = {
        ...filteredRelationFiles[crFilesIndex],
        children: files.filter(file => relationFileIds.includes(file.id) && filterFile(file))
          // Leave possible sub children - e.g. in the case of project, it's own sub projects
          .concat(filteredRelationFiles[crFilesIndex].children.filter(file => file.children))
      }
      return filteredRelationFiles
    }
  )
}

export const getRelationFilesForCustomer = createFileRelationSelector('customer', { [fileRelationTables.CUSTOMER]: 'Asiakas' })
export const getRelationFilesForProject = createFileRelationSelector('project', { [fileRelationTables.CONTACT]: 'Tilaaja' })

export const getHasFileAccess = createEveliaSelector(
  (__state, file) => file,
  findAuthorsOfCurrentEmployee,
  findCurrentEmployeeLevel,
  getEmployeeLevelsFromArgument,
  checkHasFileAccess
)

export const findFileLinksByProperty = createCachedSelector(
  getFileLinkFromArgument,
  (state, targetStoreName, targetField) => targetField,
  (state, targetStoreName, targetField, targetValue) => targetValue,
  (fileLinks, targetField, targetValue) => fileLinks.filter(fileLink =>
    fileLink[targetField] === targetValue)
)((state, targetStoreName, targetField, targetValue) => `${targetStoreName}_${targetField}_${targetValue}`)

export const getFindToolkitFileLinksByPropertySelector = selector => createCachedSelector(
  state => getToolkitFileLinkFromArgument(state, selector),
  (state, targetField) => targetField,
  (state, targetField, targetValue) => targetValue,
  (fileLinks, targetField, targetValue) => fileLinks.filter(fileLink =>
    fileLink[targetField] === targetValue)
)((state, targetField, targetValue) => `${targetField}_${targetValue}`)

export const findFileLink = createCachedSelector(
  getFileLinkFromArgument,
  (state, targetStoreName, targetIdField) => targetIdField,
  (state, targetStoreName, targetIdField, targetId, __fileId) => Number(targetId),
  (state, targetStoreName, targetIdField, targetId, fileId) => Number(fileId),
  (fileLinks, targetIdField, targetId, fileId) => fileLinks.find(fileLink => fileLink.fileId === fileId && fileLink[targetIdField] === targetId)
)((state, targetStoreName, targetIdField, targetId, fileId) => `${targetStoreName}_${targetIdField}_${targetId}_${fileId}`)

export const findFilesWithSystemTags = createCachedSelector(
  getFiles,
  (state, systemTags) => systemTags,
  (files, systemTags) => files.filter(file => (file.systemTag ?? 0) & systemTags)
)((state, systemTags) => systemTags)

export const findInboundInvoiceImageLink = createCachedSelector(
  (state, inboundInvoiceId) => findFileLinksByProperty(state, 'inboundInvoices', 'inboundInvoiceId', Number(inboundInvoiceId)),
  getFiles,
  (inboundInvoiceFileLinks, files) => {
    const imageLink = files.find(file =>
      inboundInvoiceFileLinks.find(inboundInvoicefile =>
        file.id === inboundInvoicefile.fileId &&
        inboundInvoicefile.systemTag === systemFileTags.TAG_INBOUND_INVOICE_IMAGE &&
        file.fileType === 'application/pdf')
    )
    return imageLink
  }
)((__state, inboundInvoiceId) => inboundInvoiceId)

export const findFilesWithoutSystemTagsIncludingLinks = createCachedSelector(
  getFiles,
  (state: EveliaRootState, targetStoreName: string, targetIdField: string, targetId: number) => findFileLinksByProperty(state, targetStoreName, targetIdField, Number(targetId)),
  (__state: EveliaRootState, targetStoreName: string, targetIdField: string, targetId: number, systemTags: number) => systemTags,
  (files, fileLinks, systemTags) => {
    return fileLinks.map(fileLink => {
      const file = findFileWithId(files, fileLink.fileId)
      return file && ((file.systemTag ?? 0) & systemTags) === 0 && (fileLink.systemTag & systemTags) === 0 ? file : null
    }).filter(isDefined)
  }
)((__state, targetStoreName, targetIdField, targetId, systemTags) => `${targetStoreName}_${targetIdField}_${targetId}_${systemTags}`)
