import { useDispatch } from 'react-redux'
import { removeDuplicates } from '@evelia/common/helpers'
import { InboundInvoiceModel, PurchaseOrderModel } from '@evelia/common/types'
import { Action, ThunkDispatch } from '@reduxjs/toolkit'
import { LocationDescriptor } from 'history'
import isEqual from 'lodash/isEqual'
import queryString from 'query-string'
import { push, replace } from 'redux-first-history'
import { CallHistoryMethodAction } from 'redux-first-history/build/es6/actions'
import { ValueOf } from 'type-fest'
import { parseTemplate } from 'url-template'

import contactActions from '../../actions/contactActions'
import costProvisionActions from '../../actions/costProvisionActions'
import customerActions from '../../actions/customerActions'
import projectActions from '../../actions/projectActions'
import receiverActions from '../../actions/receiverActions'
import supplierActions from '../../actions/supplierActions'
import targetActions from '../../actions/targetActions'
import warehouseActions from '../../actions/warehouseActions'
import workActions from '../../actions/workActions'
import { purchaseOrderPatchActions } from '../../constants'
import { throwIfNotInProduction } from '../../helpers/errors'
import { parseFilterParams } from '../../helpers/reducerHelpers'
import { PATH_PURCHASE_ORDERS } from '../../routes'
import { embeddedHandler as inboundInvoiceEmbeddedHandler } from '../../sagas/inboundInvoiceSaga'
import {
  createCRUDApi,
  forceRefetch,
  isRecordInCache,
  UpdateCachedData
} from './createCRUDApi'
import { basicApiNotification } from './rtkHelpers'
import { ApiResponse, EmbeddedModel, TableOptionsModel } from './types/api'

type PatchArgs = {
  body?: Partial<PurchaseOrderModel>
  id: number
  action: ValueOf<typeof purchaseOrderPatchActions>
}

type SplitPurchaseOrderArgs = {
  body: { rowsToSplit: { purchaseOrderRowId: number, receiverId: number }[] }
  id: number
}
export type GenerateArgs = (
  { purchaseOrderId: number, workId: number, action: 'create_from_work' } |
  { purchaseOrderId: number | null, offerId: number, action: 'create_from_offer', postIds: number[] | null, receiverId: number | null }

)

const getSearchQueryParams = (query: TableOptionsModel) => {
  const {
    orderBy,
    sortOrder,
    showExtraInfo,
    page,
    limit,
    noLimit,
    filters,
    q
  } = parseFilterParams(query)
  return {
    orderBy,
    sortOrder,
    showExtraInfo,
    page,
    limit,
    noLimit,
    filters,
    q
  }
}

const serializePaginatedQueryArgs = ({ queryArgs, endpointName }: { queryArgs: TableOptionsModel, endpointName?: string }) => {
  return { endpointName, queryArgs: getSearchQueryParams(queryArgs) }
}

const handleDispatchEmbeddedData = (dispatch: ThunkDispatch<unknown, unknown, Action>, embedded: EmbeddedModel) => {
  if(embedded.receivers?.length) { dispatch(receiverActions.fetchSuccess(embedded.receivers)) }
  if(embedded.suppliers?.length) { dispatch(supplierActions.fetchSuccess(embedded.suppliers)) }
  if(embedded.customers?.length) { dispatch(customerActions.fetchSuccess(embedded.customers)) }
  if(embedded.projects?.length) { dispatch(projectActions.fetchSuccess(embedded.projects)) }
  if(embedded.targets?.length) { dispatch(targetActions.fetchSuccess(embedded.targets)) }
  // @ts-expect-error sigh, work is usually as ApiResponse<WorkModel> in EmbeddedModel but purchaseOrder returns just work...
  if(embedded.work?.length) { dispatch(workActions.fetchSuccess(embedded.work)) }
  if(embedded.contacts?.length) { dispatch(contactActions.fetchSuccess(embedded.contacts)) }
  if(embedded.warehouses?.length) { dispatch(warehouseActions.fetchSuccess(embedded.warehouses)) }
  if(embedded.costProvisions?.length) { dispatch(costProvisionActions.fetchSuccess(embedded.costProvisions)) }
}

const path = 'purchaseOrders'
const PURCHASE_ORDER_TITLES = {
  singular: 'ostotilaus',
  plural: 'ostotilaukset',
  genetive: 'ostotilauksen',
  pluralGenetive: 'ostotilausten'
}
const { api: baseApi, useRecord, useRecords, useMutationsHook } = createCRUDApi<PurchaseOrderModel, TableOptionsModel, 'purchaseOrders'>({
  path,
  queryDefinition: {
    query: query => `?${queryString.stringify(getSearchQueryParams(query))}`,
    serializeQueryArgs: serializePaginatedQueryArgs,
    forceRefetch: ({ currentArg, previousArg }) => currentArg?.force || currentArg == null || previousArg == null ||
        !isEqual(serializePaginatedQueryArgs({ queryArgs: currentArg }), serializePaginatedQueryArgs({ queryArgs: previousArg })),
    providesTags: [{ type: 'purchaseOrders' }]
  },
  embeddedHandler: handleDispatchEmbeddedData,
  socketMatchers: {
    created: (_record: PurchaseOrderModel, _queryArgs: TableOptionsModel, updateCachedData: UpdateCachedData<ApiResponse<PurchaseOrderModel>>) => {
      // We can't access the api yet to invalidate the tag so we force the refetching via tableOptions.
      // This triggers refetch only once, because 'tableOptions.force' is not passed in the query to backend
      // but once response is received, the tableOptions is updated to the cache -> 'tableOptions.force' will be undefined after forced refetch
      forceRefetch(updateCachedData)
      return false
    },
    updated: (record: PurchaseOrderModel, queryArgs: TableOptionsModel, { updateCachedData, getCacheEntry }) => {
      if(queryArgs.filters != null && 'workId' in queryArgs.filters) {
        const workId = (Number(queryArgs.filters?.workId ?? '-1'))
        if(!record.workId) {
          // workId was possibly removed - make sure that the previous purchaseOrder had this workId
          const cachedRecord = getCacheEntry().data?.records?.find(({ id }) => id === record.id)
          if(cachedRecord?.workId === workId) {
            forceRefetch(updateCachedData)
            return false
          }
        } else if(record.workId === workId) {
          // Record with this workId was updated
          forceRefetch(updateCachedData)
          return false
        }
      }
      return isRecordInCache(getCacheEntry(), record.id)
    }
  },
  invalidateOnCreate: true,
  titles: PURCHASE_ORDER_TITLES
})

const api = baseApi.injectEndpoints({
  endpoints: builder => ({
    patchRecord: builder.mutation<PurchaseOrderModel, PatchArgs>({
      query: ({ body, id, action }) => ({
        url: `/${id}/${action}`,
        method: 'PATCH',
        body
      }),
      invalidatesTags: (__result, __error, { id }) => [{ type: path }, { type: path, id }],
      onQueryStarted: async({ action }, { queryFulfilled }) => {
        if(action === 'set_purchase_order_state') {
          return basicApiNotification(queryFulfilled, {
            successMessage: `Ostotilaustila päivitetty`,
            errorMessage: `Virhe ostotilaustilan päivityksessä`
          })
        }
        throwIfNotInProduction(new Error(`Unknown action ${action}`))
      }
    }),
    splitPurchaseOrder: builder.mutation<ApiResponse<PurchaseOrderModel>, SplitPurchaseOrderArgs>({
      query: ({ body, id }) => ({
        url: `/${id}/split_purchase_order`,
        method: 'POST',
        body
      }),
      invalidatesTags: (__result, __error, { id }) => [{ type: path }, { type: path, id }],
      onQueryStarted: async({ body }, { queryFulfilled }) => {
        const numReceivers = removeDuplicates(body.rowsToSplit.map(({ receiverId }) => receiverId)).length
        return basicApiNotification(queryFulfilled, {
          successMessage: `Ostotilaus jaettu ${numReceivers}:lle toimittajalle`,
          errorMessage: `Virhe ostotilauksen jakamisessa`
        })
      }
    }),
    exportRows: builder.mutation<{ excel?: string, pdf?: string }, { excel?: boolean, pdf?: boolean, id: number }>({
      query: ({ id, ...query }) => ({
        url: `/${id}/export`,
        method: 'POST',
        body: query
      }),
      invalidatesTags: (__result, __error, { id }) => [{ type: path }, { type: path, id }],
      onQueryStarted: async({ excel, pdf }, { queryFulfilled }) => basicApiNotification(queryFulfilled, {
        successMessage: `${excel && pdf ? 'Excel/pdf' : excel ? 'Excel' : 'Pdf'} luotu`,
        errorMessage: `Virhe tiedoston lataamisessa`
      })
    }),
    generatePurchaseOrder: builder.mutation<PurchaseOrderModel | PurchaseOrderModel[], GenerateArgs>({
      query: ({ purchaseOrderId, action, ...body }) => ({
        url: purchaseOrderId != null ? `${purchaseOrderId}/${action}` : `${action}`,
        method: 'POST',
        body
      }),
      onQueryStarted: async(request: GenerateArgs, { queryFulfilled }) => {
        if(request.action === 'create_from_offer' && (request.postIds?.length ?? 0) > 1) {
          return basicApiNotification(queryFulfilled, {
            successMessage: `${PURCHASE_ORDER_TITLES.plural} luotu`,
            errorMessage: `Virhe ${PURCHASE_ORDER_TITLES.pluralGenetive} luonnissa`
          })
        }
        return basicApiNotification(queryFulfilled, {
          successMessage: `${PURCHASE_ORDER_TITLES.singular} luotu`,
          errorMessage: `Virhe ${PURCHASE_ORDER_TITLES.genetive} luonnissa`
        })
      }
    }),
    getInboundInvoices: builder.query<ApiResponse<InboundInvoiceModel>, number>({
      query: (purchaseOrderId: number) => `/${purchaseOrderId}/inbound_invoices`,
      onQueryStarted: async(_req, { queryFulfilled, dispatch }) => {
        const createPromise = async() => {
          const result = await queryFulfilled
          const { data: { _embedded } } = result
          inboundInvoiceEmbeddedHandler(dispatch, _embedded)
        }
        basicApiNotification(createPromise(), {
          errorMessage: `Virhe ${PURCHASE_ORDER_TITLES.genetive} ostolaskujen haussa`,
          successMessage: null
        })
      }
    })
  })
})

export const purchaseOrderApi = api
export const {
  usePatchRecordMutation: usePatchPurchaseOrderMutation,
  useSplitPurchaseOrderMutation,
  useExportRowsMutation,
  useGeneratePurchaseOrderMutation,
  useGetInboundInvoicesQuery
} = purchaseOrderApi

export const usePurchaseOrderMutations = useMutationsHook
export const usePurchaseOrder = useRecord
export const usePurchaseOrders = useRecords

const purchaseOrderTemplate = parseTemplate(`${PATH_PURCHASE_ORDERS}{/purchaseOrderId}{?source}`)
type PurchaseOrderTemplateParams = { source?: string }
export const getPurchaseOrderUrl = (purchaseOrderId?: number, params?: PurchaseOrderTemplateParams) =>
  purchaseOrderTemplate.expand({ purchaseOrderId: purchaseOrderId ?? 'new', ...params })

type GetUrlType = {
  replaceUrl?: boolean
  purchaseOrderId?: number
}
export type NavigateToPurchaseOrderType = (id?: number, options?: GetUrlType) => CallHistoryMethodAction<[location: LocationDescriptor<unknown>, state?: unknown]>
export const useNavigateToPurchaseOrder = (params?: PurchaseOrderTemplateParams): NavigateToPurchaseOrderType => {
  const dispatch = useDispatch()
  return (id?: number, options?: GetUrlType) => dispatch(options?.replaceUrl ? replace(getPurchaseOrderUrl(id, params)) : push(getPurchaseOrderUrl(id, params)))
}

export const useNavigateToPurchaseOrderList = () => {
  const dispatch = useDispatch()
  return (options?: GetUrlType) => dispatch(options?.replaceUrl ? replace(PATH_PURCHASE_ORDERS) : push(PATH_PURCHASE_ORDERS))
}
