import { appendEitherOrEmpty, appendValuesOrEmpty } from '@evelia/common/helpers'
import {
  all,
  call,
  put,
  takeEvery,
  takeLatest
} from 'redux-saga/effects'

import contactActions from '../actions/contactActions'
import customerActions from '../actions/customerActions'
import employeeActions from '../actions/employeeActions'
import fileActions from '../actions/fileActions'
import offerActions from '../actions/offerActions'
import projectActions from '../actions/projectActions'
import targetActions from '../actions/targetActions'
import {
  createOffer,
  createOfferPost,
  createOfferPostRow,
  deleteOffer,
  deleteOfferPost,
  deleteOfferPostRow,
  doOfferAction,
  doOfferPatchAction,
  doOfferPostPatchAction,
  doOfferPostRowPostAction,
  doOfferPostRowPutAction,
  fetchOfferDefaults,
  fetchOfferPostRows,
  fetchOfferPosts,
  fetchOffers,
  fileApi,
  generateOfferPostRowReport,
  importPostRowsFromFile,
  normalizeOffers,
  searchOffers,
  updateOffer,
  updateOfferDefaults,
  updateOfferPost,
  updateOfferPostRow
} from '../api/offerApi'
import { actionTypes } from '../constants'
import { defaultNormalizer } from '../helpers/apiHelpers'
import { getMemoSagas } from '../helpers/generalSagas'
import { addSuccessNotification } from '../helpers/notificationHelpers'
import {
  createActionFlow,
  createBulkSocketWatcherWithApiHandlerAndNormalizer,
  createFlow,
  createSocketWatcherWithApiHandlerAndNormalizer,
  defaultApiResponseHandler,
  deleteFlow,
  fetchFlow,
  generateReportFlow,
  genericSagaErrorHandler,
  getPromiseHandlersFromData,
  getRecordsFromState,
  getSubEntitySagas,
  getSubSagas,
  searchFlow,
  updateFlow,
  updateRowRankFlow
} from '../helpers/sagaHelpers'
import { handleEmployeeApiResponse } from './employeeSaga'

const handleOfferApiResponse = (mainAction, tableIdentifier = 'default') =>
  function* ({
    data,
    customers,
    targets,
    projects,
    contacts,
    employees,
    tableOptions,
    extraInfo
  }) {
    yield put(mainAction(data))
    yield put(customerActions.fetchSuccess(customers))
    yield put(targetActions.fetchSuccess(targets))
    yield put(projectActions.fetchSuccess(projects))
    yield put(offerActions.fetchExtraInfoSuccess(extraInfo))
    yield put(contactActions.fetchSuccess(contacts))
    yield handleEmployeeApiResponse(employeeActions.fetchSuccess, false)(employees)
    if(tableOptions && tableOptions.orderBy != null) {
      yield put(offerActions.tableActions.updateOptions(tableOptions, tableIdentifier))
    }
    return data
  }

const handleOfferPostRowApiResponse = defaultApiResponseHandler

const watchOnOfferSockets = createSocketWatcherWithApiHandlerAndNormalizer('offer', offerActions, handleOfferApiResponse, normalizeOffers)
const watchOnOfferPostSockets = createSocketWatcherWithApiHandlerAndNormalizer('offerPost', offerActions.posts, defaultApiResponseHandler, defaultNormalizer)
const watchOnOfferPostRowSockets = createSocketWatcherWithApiHandlerAndNormalizer('offerPostRow', offerActions.postRows, handleOfferPostRowApiResponse, defaultNormalizer)
const watchOnOfferPostRowBulkSockets = createBulkSocketWatcherWithApiHandlerAndNormalizer('offerPostRow:bulk', offerActions.postRows, handleOfferPostRowApiResponse, defaultNormalizer)

const offerFetchFlow = fetchFlow({
  fetchApi: fetchOffers,
  actions: offerActions,
  base: 'offers',
  errorMsg: 'tarjousten',
  getApiResponseHandler: data => handleOfferApiResponse(offerActions.fetchSuccess, data.tableIdentifier ?? 'default')
})

const offerUpdateFlow = updateFlow(updateOffer, offerActions, 'Tarjous', 'Tarjouksen', handleOfferApiResponse(offerActions.updateSuccess))
const offerCreateFlow = createFlow(createOffer, offerActions, 'Tarjous', 'Tarjouksen', handleOfferApiResponse(offerActions.createSuccess))
const offerDeleteFlow = deleteFlow({
  deleteApi: deleteOffer,
  actions: offerActions,
  singular: 'Tarjous',
  errorMsg: 'Tarjouksen',
  base: 'offers'
})
const offerSearchFlow = searchFlow(searchOffers, offerActions, 'Tarjousten', function* (data, searchTerm) {
  const offers = yield handleOfferApiResponse(offerActions.fetchSuccess)(data)
  yield put(offerActions.searchSuccess(offers, searchTerm))
  return offers
})

const offerPatchActionFlow = createActionFlow(doOfferPatchAction, offerActions, handleOfferApiResponse, true)

const {
  subFetchFlow: offerPostsFetchFlow,
  subCreateFlow: offerPostCreateFlow,
  subUpdateFlow: offerPostUpdateFlow,
  subDeleteRecursiveFlow: offerPostDeleteFlow
} = getSubSagas({
  mainActions: offerActions,
  mainReduxName: 'offers',
  subReduxName: 'posts',
  pathField: 'offerPostPath'
}, {
  fetchApi: fetchOfferPosts,
  createApi: createOfferPost,
  updateApi: updateOfferPost,
  deleteApi: deleteOfferPost
}, {
  singular: 'Tarjouksen posti',
  accusative: 'Tarjouksen postin',
  fetchError: 'Virhe tarjouksen postien noutamisessa',
  deleteSuccess: 'Tarjouksen posti poistettu',
  deleteError: 'Virhe tarjouksen postin poistamisessa'
})

const {
  subFetchFlow: offerPostRowsFetchFlow,
  subCreateFlow: offerPostRowCreateFlow,
  subUpdateFlow: offerPostRowUpdateFlow,
  subDeleteFlow: offerPostRowDeleteFlow
} = getSubSagas({
  mainActions: offerActions,
  mainReduxName: 'offers',
  subReduxName: 'postRows'
}, {
  fetchApi: fetchOfferPostRows,
  createApi: createOfferPostRow,
  updateApi: updateOfferPostRow,
  deleteApi: deleteOfferPostRow
}, {
  singular: 'Postin rivi',
  accusative: 'Postin rivin',
  fetchError: 'Virhe postin rivien noutamisessa',
  deleteSuccess: 'Postin rivi poistettu',
  deleteError: 'Virhe postin rivin poistamisessa'
})

function* doOfferPostRowPostActionFlow({ record, data }) {
  const { resolve, reject } = getPromiseHandlersFromData(data)
  try {
    const response = yield call(doOfferPostRowPostAction, record, data)
    yield put(offerActions.postRows.fetchSuccess(response))
    addSuccessNotification('Toiminto onnistui')
    yield call(resolve, response)
  } catch(err) {
    yield * genericSagaErrorHandler(err, appendValuesOrEmpty(['Toiminto epäonnistui', err?.json?.message], ': '), reject)
  }
}

function* doOfferActionFlow({ record, data }) {
  const { resolve, reject } = getPromiseHandlersFromData(data)
  try {
    const response = yield call(doOfferAction, record, data)
    addSuccessNotification('Toiminto onnistui')
    if(data.subItem === 'copy') {
      yield put(offerActions.fetchSuccess(response.data))
      yield put(offerActions.posts.fetchSuccess(response.posts))
      yield put(offerActions.postRows.fetchSuccess(response.rows))
    }
    yield call(resolve, response)
  } catch(err) {
    yield * genericSagaErrorHandler(err, appendValuesOrEmpty(['Toiminto epäonnistui', err?.json?.message], ': '), reject)
  }
}

const generateOfferRowReportFlow = generateReportFlow(generateOfferPostRowReport)

const fileFlows = getSubEntitySagas(offerActions, fileActions, fileApi.fetchApi, fileApi.createApi, fileApi.deleteApi, 'offerId', 'fileId', 'offers', 'files', {
  singular: 'Tarjouksen tiedosto',
  accusative: 'Tarjouksen tiedoston',
  fetchError: 'Virhe tarjouksen tiedostojen noutamisessa',
  deleteSuccess: 'Tarjouksen tiedostolinkki poistettu',
  deleteError: 'Virhe tarjouksen tiedostolinkin poistamisessa'
})

const offerDefaultsFetchFlow = fetchFlow({
  fetchApi: fetchOfferDefaults,
  actions: offerActions.offerDefaults,
  base: 'offers.offerDefaults',
  errorMsg: 'tarjousten oletusten',
  shouldPerformRequest: function* (base) {
    const record = yield getRecordsFromState(base)
    return !record
  }
})
const offerDefaultsUpdateFlow = updateFlow(updateOfferDefaults, offerActions.offerDefaults, 'Tarjouksen oletukset', 'Tarjouksen oletusten')

const memoSagas = getMemoSagas({
  actions: offerActions,
  baseName: 'offers',
  socketName: 'offer',
  titleGenetive: 'tarjouksen'
})

const offerPostPatchActionFlow = createActionFlow(doOfferPostPatchAction, offerActions.posts)

function* offerPostRowImportFlow({ offerId, files, subItemType }) {
  try {
    addSuccessNotification('Tiedoston käsittely aloitettu')
    const response = yield call(importPostRowsFromFile, offerId, files, subItemType)
    yield put(offerActions.postRows.fetchSuccess(response.data))
    yield put(offerActions.posts.fetchSuccess(response.posts))
    addSuccessNotification('Postirivien tuonti valmis')
  } catch(err) {
    yield * genericSagaErrorHandler(err, appendEitherOrEmpty('Virhe postirivien tuonnissa', err.json?.message))
  }
}

const updateOfferPostRowRankFlow = updateRowRankFlow(doOfferPostRowPutAction, offerActions.postRows)

const offerPostRowPutActionFlow = createActionFlow(doOfferPostRowPutAction, offerActions.postRows)

export default function* offerSaga() {
  yield takeLatest(offerActions.actionTypes.fetchRequest, offerFetchFlow)
  yield takeEvery(offerActions.actionTypes.updateRequest, offerUpdateFlow)
  yield takeEvery(offerActions.actionTypes.createRequest, offerCreateFlow)
  yield takeEvery(offerActions.actionTypes.deleteRequest, offerDeleteFlow)
  yield takeLatest(offerActions.actionTypes.searchRequest, offerSearchFlow)

  yield takeEvery(offerActions.postRows.actionTypes.fetchRequest, offerPostRowsFetchFlow)
  yield takeEvery(offerActions.postRows.actionTypes.updateRequest, offerPostRowUpdateFlow)
  yield takeEvery(offerActions.postRows.actionTypes.createRequest, offerPostRowCreateFlow)
  yield takeEvery(offerActions.postRows.actionTypes.deleteRequest, offerPostRowDeleteFlow)

  yield takeEvery(offerActions.posts.actionTypes.fetchRequest, offerPostsFetchFlow)
  yield takeEvery(offerActions.posts.actionTypes.updateRequest, offerPostUpdateFlow)
  yield takeEvery(offerActions.posts.actionTypes.createRequest, offerPostCreateFlow)
  yield takeEvery(offerActions.posts.actionTypes.deleteRequest, offerPostDeleteFlow)

  yield takeEvery(actionTypes.OFFER_ACTION_REQUEST, doOfferActionFlow)
  yield takeEvery(actionTypes.OFFER_POST_ROWS_ACTION_REQUEST, doOfferPostRowPostActionFlow)
  yield takeLatest(actionTypes.OFFER_GENERATE_REPORT_REQUEST, generateOfferRowReportFlow)

  yield takeEvery(actionTypes.OFFER_PATCH_ACTION_REQUEST, offerPatchActionFlow)

  yield takeLatest(offerActions.files.actionTypes.fetchRequest, fileFlows.subFetchFlow)
  yield takeEvery(offerActions.files.actionTypes.createRequest, fileFlows.subCreateFlow)
  yield takeLatest(offerActions.files.actionTypes.deleteRequest, fileFlows.subDeleteFlow)

  yield takeEvery(offerActions.offerDefaults.actionTypes.fetchRequest, offerDefaultsFetchFlow)
  yield takeEvery(offerActions.offerDefaults.actionTypes.updateRequest, offerDefaultsUpdateFlow)

  yield takeEvery(actionTypes.OFFER_POST_PATCH_ACTION_REQUEST, offerPostPatchActionFlow)
  yield takeLatest(actionTypes.OFFER_POST_IMPORT_ROWS_REQUEST, offerPostRowImportFlow)

  yield takeLatest(offerActions.postRows.actionTypes.updateRankRequest, updateOfferPostRowRankFlow)
  yield takeEvery(actionTypes.OFFER_POST_ROW_PUT_ACTION_REQUEST, offerPostRowPutActionFlow)

  yield all([
    memoSagas(),
    watchOnOfferSockets(),
    watchOnOfferPostSockets(),
    watchOnOfferPostRowSockets(),
    watchOnOfferPostRowBulkSockets()
  ])
}
