import { EmploymentSalaryTypeModel, SalaryTypeModel, WageEarnerModel } from '@evelia/common/types'
import { createCachedSelector } from 're-reselect'
import { createSelector } from 'reselect'
import type { RequireExactlyOne } from 'type-fest'

import { EveliaRootState } from '../reducerTypes'
import { employmentSalaryTypeEntitySelectors } from '../slices/employmentSalaryTypeSlice'
import { employmentEntitySelectors } from '../slices/employmentSlice'
import { wageEarnerEntitySelectors } from '../slices/wageEarnerSlice'

const getWageEarnersFromState = createSelector(
  (state: EveliaRootState) => state.wageEarners.wageEarners.entities,
  wageEarners => Object.values(wageEarners)
)

interface FindWithIdArguments {
  state?: EveliaRootState
  wageEarners?: WageEarnerModel[]
  wageEarnerId: number
}

export const findWageEarnerWithId = createSelector(
  [
    ({ state, wageEarners }: RequireExactlyOne<FindWithIdArguments, 'state' | 'wageEarners'>) => state == null ? wageEarners : getWageEarnersFromState(state),
    ({ wageEarnerId }: FindWithIdArguments) => wageEarnerId
  ],
  (wageEarners, wageEarnerId) => wageEarners.find(wageEarner => wageEarner.id === wageEarnerId)
)

export const findTaxDeductionCardsByWageEarnerId = createCachedSelector(
  [
    (state: EveliaRootState, __wageEarnerId: number) => state.wageEarners.taxDeductionCards.entities,
    (__state: EveliaRootState, wageEarnerId: number) => wageEarnerId
  ],
  (taxDeductionCards, wageEarnerId) => Object.values(taxDeductionCards).filter(taxDeductionCard => taxDeductionCard.wageEarnerId === wageEarnerId)
)((__state, wageEarnerId) => `${wageEarnerId}`)

export const findEmploymentsByWageEarnerId = createCachedSelector(
  [
    (state: EveliaRootState, __wageEarnerId: number) => state.wageEarners.employments.entities,
    (__state: EveliaRootState, wageEarnerId: number) => wageEarnerId
  ],
  (employments, wageEarnerId) => Object.values(employments).filter(employment => employment.wageEarnerId === wageEarnerId)
)((__state, wageEarnerId) => `${wageEarnerId}`)

export type EmploymentSalaryTypeWithOverrides = {
  defaultPrice?: number | null
  hasEmploymentData?: boolean
  price?: number | null
} & Omit<Partial<SalaryTypeModel>, 'price'> & Partial<EmploymentSalaryTypeModel> & { name: SalaryTypeModel['name'] }

export const getAllEmploymentSalaryTypesWithOverrides = createCachedSelector(
  (state: EveliaRootState) => state.wageEarners.employmentSalaryTypes.entities,
  state => state.systemCustomer.salaryTypes.records,
  employmentEntitySelectors.selectById,
  (__state: EveliaRootState, employmentId: number) => employmentId,
  (allEmploymentSalaryTypeEntities, salaryTypes, employment, employmentId): EmploymentSalaryTypeWithOverrides[] => {
    const salaryTypeIds = employment?.salaryTypeIds ?? []
    const allEmploymentSalaryTypes = Object.values(allEmploymentSalaryTypeEntities)
    const employmentSalaryTypes = allEmploymentSalaryTypes.filter(employmentSalaryType => employmentSalaryType.employmentId === employmentId)

    return salaryTypeIds.map(salaryTypeId => {
      const employmentSalaryType = employmentSalaryTypes.find(employmentSalaryType => employmentSalaryType.salaryTypeId === salaryTypeId)
      const { id: __salaryTypeId, price: defaultPrice, ...salaryType } = salaryTypes.find(salaryType => salaryType.id === salaryTypeId) ?? { name: '?' }
      return {
        ...salaryType,
        salaryTypeId,
        defaultPrice,
        ...employmentSalaryType,
        price: employmentSalaryType?.price ?? null,
        isIncludedInReports: employmentSalaryType?.isIncludedInReports ?? false,
        hasEmploymentData: !!employmentSalaryType
      }
    })
  }
)((__state, employmentId) => `${employmentId}`)

export const getAllWageEarnerSalaryTypesWithOverrides = createCachedSelector(
  employmentSalaryTypeEntitySelectors.selectAll,
  state => state.systemCustomer.salaryTypes.records,
  wageEarnerEntitySelectors.selectById,
  (__state: EveliaRootState, wageEarnerId: number) => wageEarnerId,
  (allEmploymentSalaryTypes, salaryTypes, wageEarner, wageEarnerId): EmploymentSalaryTypeWithOverrides[] => {
    const salaryTypeIds = wageEarner?.salaryTypeIds ?? []
    const employmentSalaryTypes = allEmploymentSalaryTypes.filter(employmentSalaryType => employmentSalaryType.wageEarnerId === wageEarnerId)
    return salaryTypeIds.map(salaryTypeId => {
      const employmentSalaryTypesOfSalaryType = employmentSalaryTypes.filter(employmentSalaryType => employmentSalaryType.salaryTypeId === salaryTypeId)
      const { id: __salaryTypeId, price: defaultPrice, ...salaryType } = salaryTypes.find(salaryType => salaryType.id === salaryTypeId) ?? { name: '?' }
      return {
        ...salaryType,
        salaryTypeId,
        defaultPrice,
        isIncludedInReports: employmentSalaryTypesOfSalaryType.some(employmentSalaryType => !!employmentSalaryType?.isIncludedInReports)
      }
    })
  }
)((__state, employmentId) => `${employmentId}`)
