import { NullableId, ProductWarehouseModel } from '@evelia/common/types'
import { Action, ThunkDispatch } from '@reduxjs/toolkit'
import { createApi } from '@reduxjs/toolkit/query/react'

import productActions from '../../actions/productActions'
import warehouseActions from '../../actions/warehouseActions'
import { updatePurchaseOrdersUpdatedNotification } from '../../containers/PurchaseOrderPage/PurchaseOrdersUpdatedNotifications'
import { addSuccessNotification } from '../../helpers/notificationHelpers'
import { getBaseQuery } from './apiHelpers'
import { purchaseOrderRowApi } from './purchaseOrderRowApi'
import { basicApiNotification } from './rtkHelpers'
import { ApiRecordResponse, ApiResponse, EmbeddedModel } from './types/api'
import { warehouseApi } from './warehouseApi'

const handleDispatchEmbeddedData = (dispatch: ThunkDispatch<unknown, unknown, Action>, embedded: EmbeddedModel) => {
  if(embedded.warehouses?.length) { dispatch(warehouseActions.fetchSuccess(embedded.warehouses)) }
  if(embedded.productWarehouseStats) { dispatch(productActions.productWarehouseStats.fetchSuccess(embedded.productWarehouseStats)) }
}

export const productWarehouseApi = createApi({
  reducerPath: 'productWarehouseApi',
  baseQuery: getBaseQuery(''),
  endpoints: builder => ({
    getProductWarehouses: builder.query<ApiResponse<ProductWarehouseModel>, { productId: number }>({
      query: ({ productId }) => `products/${productId}/warehouses`,
      onQueryStarted: async(__args, { dispatch, queryFulfilled }) => {
        const createPromise = async() => {
          const { data: { _embedded } } = await queryFulfilled
          handleDispatchEmbeddedData(dispatch, _embedded)
        }
        await basicApiNotification(createPromise(), {})
      }
    }),
    createRecord: builder.mutation<ApiRecordResponse<ProductWarehouseModel>, NullableId<ProductWarehouseModel>>({
      query: body => ({
        url: `products/${body.productId}/warehouses/${body.warehouseId}`,
        method: 'POST',
        body
      }),
      onQueryStarted: async({ shelfName }, { dispatch, queryFulfilled }) => {
        const createPromise = async() => {
          const { data: { record } } = await queryFulfilled
          dispatch(productWarehouseApi.util.updateQueryData('getProductWarehouses', { productId: record.productId }, state => {
            state.records = state.records.concat(record)
          }))
          if(shelfName != null) {
            dispatch(warehouseApi.util.updateQueryData('getWarehouseShelves', { warehouseId: record.warehouseId }, shelves =>
              [...new Set([...shelves, shelfName])]
            ))
          }
        }
        await basicApiNotification(createPromise(), {
        // User doesn't know and doesn't need to know that it didn't exists previously.
          successMessage: `Tuotteen varastotiedot päivitetty`,
          errorMessage: `Virhe tuotteen varastotietojen päivityksessä'`
        })
      }
    }),
    updateRecord: builder.mutation<ApiRecordResponse<ProductWarehouseModel>, ProductWarehouseModel>({
      query: body => ({
        url: `products/${body.productId}/warehouses/${body.warehouseId}`,
        method: 'PUT',
        body
      }),
      onQueryStarted: async({ id, ...rest }, { dispatch, queryFulfilled }) => {
        const patchResult = dispatch(productWarehouseApi.util.updateQueryData('getProductWarehouses', { productId: rest.productId }, state => {
          state.records = state.records.map(record => record.id === id ? { ...record, ...rest } : record)
        }))
        const createPromise = async() => {
          await queryFulfilled
          dispatch(warehouseApi.util.invalidateTags([{ type: 'shelves', id: rest.warehouseId }]))
        }
        await basicApiNotification(createPromise(), {
          successMessage: `Tuotteen varastotiedot päivitetty`,
          errorMessage: `Virhe tuotteen varastotietojen päivityksessä'`,
          onError: patchResult.undo
        })
      }
    }),
    warehouseAutoFill: builder.mutation<{ created: number[], updated: number[] }, void>({
      query: () => ({
        url: 'warehouses/autoFill',
        method: 'POST'
      }),
      onQueryStarted: async(__args, { dispatch, queryFulfilled }) => {
        const toastId = addSuccessNotification('Varastojen automaattitäyttö aloitettu')
        const createPromise = async() => {
          const { data: { created, updated } } = await queryFulfilled
          updatePurchaseOrdersUpdatedNotification(toastId, created, updated)
          updated.forEach(id => {
            dispatch(purchaseOrderRowApi.util.invalidateTags([{ type: 'Rows', id }]))
          })
          if(updated.length) {
            dispatch(purchaseOrderRowApi.util.invalidateTags(updated.map(id => ({ type: 'Rows', id }))))
          }
        }
        await basicApiNotification(createPromise(), {
          errorMessage: `Virhe varastojen automaattitäytössä`
        })
      }
    })
  })
})

export const {
  useGetProductWarehousesQuery,
  useWarehouseAutoFillMutation
} = productWarehouseApi

export const useProductWarehouseMutations = () => {
  const [create] = productWarehouseApi.useCreateRecordMutation()
  const [update] = productWarehouseApi.useUpdateRecordMutation()
  return { create, update }
}
