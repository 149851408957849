// TODO: missing typings
import isFunction from 'lodash/isFunction.js'
import isString from 'lodash/isString.js'
import moment from 'moment'
import type { Primitive } from 'type-fest'

import { emptyArray } from './constants.js'
import { BankAccountModel } from './types/bankAccount.js'
import { PrivateEmployeeModel } from './types/employee.js'
import { personalIdentityCodeToDate } from './yup/validators/isValidPersonalIdentityCode.js'

type NumberOrString = string | number
type NullableNumberOrString = NumberOrString | null

export * from './pluralize.js'

export const capitalize = (string: string) => string && string[0].toUpperCase() + string.slice(1)

export const toSnakeCase = (...texts: string[]) => texts.map(text => text.toLowerCase()).reduce((string, word, index) =>
  string + (index === 0 ? word : '_' + word), '')

export const toCamelCase = (...texts: string[]) => texts.map(text => text.trim().toLowerCase()).reduce((string, word, index) =>
  string + (index === 0 ? word : capitalize(word)), '')

export const toPascalCase = (...texts: string[]) => texts.map(text => text.trim().toLowerCase()).reduce((string, word) =>
  string + capitalize(word), '')

export const castToArray = <T>(data: T | T[]): NonNullable<T>[] => (Array.isArray(data) ? data : (data ? [data] : emptyArray)) as NonNullable<T>[]

export const appendValuesOrEmpty = (values: Primitive[], delimiter = ' ') => castToArray(values).filter(Boolean).join(delimiter)

export const appendEitherOrEmpty = (a: Primitive, b: Primitive, delimiter = ' ') => appendValuesOrEmpty([a, b], delimiter)

export const getSelf = <T>(data: T) => data

export const numberSort = (a: number, b: number) => a - b

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const sortByProperty = <T extends object>(property: keyof T, sortDescending = false, castFn: (value: any) => NumberOrString = getSelf) => (a: T, b: T) => {
  const aProperty = castFn(a[property])
  const bProperty = castFn(b[property])
  const result = aProperty < bProperty ? -1 : (aProperty > bProperty ? 1 : 0)
  return sortDescending
    ? result * -1
    : result
}

export const sortById = sortByProperty('id')

export const sortByIdDesc = sortByProperty('id', true)

export const decimalToPercent = (value: number) => value * 100

export const percentToDecimal = (value: number | null) => ((value ?? 0) / 100)

export const getCoefficient = (percent: number) => 1 + percentToDecimal(percent)

export const getDiscountCoefficient = (percent: number) => 1 - percentToDecimal(percent)

export const calculateGrossProfitPrice = (price: number, grossProfit: number) => 100 * price / (100 - grossProfit)

export const roundNumber = (num: NumberOrString, scale: number) => { // https://stackoverflow.com/a/12830454
  if(scale == null) {
    throw new Error('No scale!')
  }
  if(!('' + num).includes('e')) {
    return +(Math.round(Number(num + 'e+' + scale)) + 'e-' + scale)
  } else {
    const arr = ('' + num).split('e')
    let sig = ''
    if(+arr[1] + scale > 0) {
      sig = '+'
    }
    return +(Math.round(Number(+arr[0] + 'e' + sig + (+arr[1] + scale))) + 'e-' + scale)
  }
}

export const round1decimals = (value: NumberOrString) => roundNumber(value, 1)
export const round2decimals = (value: NumberOrString) => roundNumber(value, 2)
export const round3decimals = (value: NumberOrString) => roundNumber(value, 3)
export const round4decimals = (value: NumberOrString) => roundNumber(value, 4)
export const round6decimals = (value: NumberOrString) => roundNumber(value, 6)
export const round8decimals = (value: NumberOrString) => roundNumber(value, 8)

export const roundUpToNext = (value: number, roundValue: number, precision = 8) =>
  value && roundValue
    ? roundNumber((value > 0
      ? Math.ceil(value / roundValue)
      : Math.floor(value / roundValue)) * roundValue, precision)
    : roundNumber(value, precision)

export const percentString = (value: NumberOrString, decimal = 1) => `${roundNumber(value, decimal)}%`
export const numberToCommaString = (numeric: NumberOrString) => numeric.toString().replace('.', ',')

/**
 * Inspiration: https://stackoverflow.com/questions/2901102/how-to-format-a-number-with-commas-as-thousands-separators/2901298#2901298
 *
 * The regex uses 2 lookahead assertions:
 *  - a positive one to look for any point in the string that has a multiple of 3 digits in a row after it,
 *  - a negative assertion to make sure that point only has exactly a multiple of 3 digits
 *  - Note that this function will format decimals as well if provided
 */
export const separateThousandsBySpace = (value: NullableNumberOrString | undefined, isWithoutSpaces = false) => {
  if(value == null) {
    return ''
  } else if(value === '-0') {
    // String representation of `-0` is '0' so sign must be added manually
    return '-0'
  }
  return Number(value).toString().replace(/\B(?=(\d{3})+(?!\d))/g, isWithoutSpaces ? '' : ' ')
}

export const formatThousandsSeparatedBySpaces = (value?: NullableNumberOrString, isWithoutSpaces = false, unit = '') => {
  if(value == null || Number.isNaN(+value)) {
    return ''
  }
  const roundValue = round2decimals(value).toFixed(2)
  const [integerPart, decimalPart] = roundValue.split('.')

  return `${separateThousandsBySpace(integerPart, isWithoutSpaces)},${decimalPart}${unit}`
}

export const roundIntegerAndFormatThousandsSeparatedBySpaces = value => {
  const integerPart = roundNumber(value, 0)
  return separateThousandsBySpace(integerPart)
}

export const formatWithUnit =
  (unit: string | undefined | null, formatter: (value: NumberOrString) => NumberOrString = round2decimals) =>
    (value?: NullableNumberOrString) =>
      value == null || Number.isNaN(+value)
        ? '-'
        : `${formatter(+value)}${unit ?? ''}`

export const formatEur = formatWithUnit('€', formatThousandsSeparatedBySpaces)

export const getPopulatedText = (templateText, data, renderers, empty = '') => {
  if(!templateText) {
    return templateText
  }
  const getKeys = templateText.match(/[^[\]]+(?=])/g)
  return getKeys
    ? getKeys.reduce((acc, key) => {
      const keyRenderer = renderers[key]
      const replacedKey = keyRenderer?.(data)
      return replacedKey ? acc.replace(`[${key}]`, replacedKey) : acc.replace(`[${key}]`, empty)
    }, templateText)
    : templateText
}

/**
 * Try to find bank account marked for invoicing with id or first possible bank account from the list as fallback
 */
export const getInvoiceBankAccount = (bankAccounts: BankAccountModel[], bankAccountId: number, useFirstIfNull: boolean) => {
  if(!bankAccounts) {
    return null
  }
  const invoiceBankAccounts = bankAccounts.filter(bankAccount => bankAccount.isInvoiceBankAccount)

  const bankAccount = invoiceBankAccounts.find(bankAccount => bankAccount.id === Number(bankAccountId))
  return bankAccount || (
    useFirstIfNull
      ? invoiceBankAccounts[0] || bankAccounts[0]
      : null
  )
}

export const hidePhoneNumber = (phoneNumber: string) => {
  if(!phoneNumber) {
    return ''
  }
  const numberArray = phoneNumber.split('')
  return numberArray.map((number, index) => {
    if(index < 3 || index > phoneNumber.length - 4) {
      return number
    }
    return '*'
  }).join('')
}

export const isValidForRtv = model => {
  // Ignore employer checks if person is own employee without subcontracting data
  const ignoreEmployerFields = model.isSubcontractor != null && !model.isSubcontractor
  const hasPersonalInfo = model.personalIdentityCode != null || !!(model.taxNumber && model.dateOfBirth)
  const hasEmployerFields = !!(model.employerName && model.employerBusinessId && model.personType)
  return hasPersonalInfo && (ignoreEmployerFields || hasEmployerFields)
}

export const finiteNumber = (result, fallbackValue: NumberOrString = 0) => {
  if(!Number.isFinite(result)) {
    return fallbackValue
  }
  return result
}

export const getRightAddress = ({ address, city, postalCode }: { address?: string, city?: string, postalCode?: string } = {}) => {
  const cityPostal = appendEitherOrEmpty(postalCode, city)
  return appendEitherOrEmpty(address, cityPostal, ', ') || null
}

export const parseCountryAndPostalCode = (postalCodeToParse?: string) => {
  if(!postalCodeToParse) {
    return {}
  }
  if(postalCodeToParse.match(/^\d{5}$/)) {
    return {
      country: 'FI',
      postalCode: postalCodeToParse
    }
  } else {
    const [country, postalCode] = postalCodeToParse.split('-')
    if(postalCode) {
      return {
        country,
        postalCode
      }
    } else {
      return {
        country: 'FI',
        postalCode: country
      }
    }
  }
}

export const getIsAddressPObox = ({ address, postalCode, country }: { address?: string | null, postalCode?: string | null, country?: string | null }) => {
  const addressUpperCase = address?.toUpperCase()
  return country === 'FI' && postalCode?.length === 5 && postalCode?.endsWith('1') && (addressUpperCase?.startsWith('PL') || addressUpperCase?.startsWith('PB'))
}

const extractStreetAndNumber = (address: string) => {
  const parts = address.split(' ') // Split the address by spaces
  let foundNumber = false

  // Iterate through the parts to build the street name and house number
  const streetNameAndNumberArr = parts.map((part, i) => {
    if(!foundNumber) {
      if(/^\d+$/.test(part) && i > 0) {
        foundNumber = true
      }
      return part
    } else {
      return null
    }
  }).filter(isDefined)

  return streetNameAndNumberArr.join(' ')
}

// Cleans the street address for better matches from Geoapify (OpenStreetMap does not have entrance or apartment number info)
export const getCleanedAddressForGeocoding = (address?: string) => {
  if(!address) {
    return null
  }

  const cleanedAddress = address.toUpperCase()
    .replace('C/O', '').trim() // Remove 'C/O' from address
    .replace(/\([^()]*\)/g, '').trim() // Remove anything within parentheses, including the parentheses themselves
    .replaceAll(',', '').trim() // Remove commas
    .replaceAll('/', ' ').trim() // Replace slashes with spaces
    .replace(/(?<=\d)-(?=\d)/g, ' - ') // Pad dashes between numbers with spaces
    .replace(/(?<=\d)(?=[A-Z])|(?<=[A-Z])(?=\d)/g, ' ') // Add spaces between other characters and numbers that are next to each other (eg. Testitie3 A1 -> Testitie 3 A 1)

  return extractStreetAndNumber(cleanedAddress)
}

export const getAddressQueryString = ({ address, city, postalCode, country }) => {
  return `${getCleanedAddressForGeocoding(address)}, ${city}, ${postalCode}, ${country}`
}

const hasPostalCodeMinorDiffsOrNone = (postalCode1, postalCode2, matchType, confidence, maxConf) => {
  if(!postalCode1 || !postalCode2) {
    return false
  }
  if(postalCode1 === postalCode2) {
    return true
  }
  // Check that the length is the same
  if(postalCode1.length !== postalCode2.length) {
    return false
  }
  // Check if the first two digits are the same
  if(postalCode1.substring(0, 2) !== postalCode2.substring(0, 2)) {
    return false
  }

  const code1Arr = [...postalCode1]
  const code2Arr = [...postalCode2]

  // Get count of differences in the rest of the digits
  let diffCount = 0
  code1Arr.forEach((digit, i) => {
    if(digit !== code2Arr[i]) {
      diffCount++
    }
  })

  // If the Geoapify match type is full_match with maximum confidence level, allow 2 differences (if the subtracted difference of the postal codes is less than 250)
  if(matchType === 'full_match' && confidence === maxConf) {
    const postalCodeTotalSubtraction = Math.abs(postalCode1 - postalCode2)
    return diffCount <= 2 && postalCodeTotalSubtraction < 250
  // If the match type is inner_part, allow only one difference
  } else {
    return diffCount <= 1
  }
}

// Tests if Geoapify result confidence level is acceptable and match type = 'full match' or 'inner_part' to find a reliable address match
export const isAddressMatchGeoapify = (data, postalCode) => {
  const {
    confidence,
    confidence_street_level: confidenceStreet,
    confidence_city_level: confidenceCity,
    match_type: matchType
  } = data.rank
  const { postcode: resultPostalCode } = data
  const acceptableMatchTypes = ['full_match', 'inner_part']
  const maxConf = 1
  const minAcceptableConf = 0.9
  const isPostalCodeAcceptable = hasPostalCodeMinorDiffsOrNone(postalCode, resultPostalCode, matchType, confidence, maxConf)

  return acceptableMatchTypes.includes(matchType) && isPostalCodeAcceptable &&
    (confidence === maxConf ||
      (confidence >= minAcceptableConf && confidenceStreet === maxConf && confidenceCity === maxConf))
}

export const callFunctionOrContent = (content, ...args) => isFunction(content) ? content(...args) : content

export const toIdMap = (idObjects: { id: NumberOrString }[]) =>
  idObjects.reduce((acc, current) => ({
    ...acc,
    [current.id]: current
  }), {})

interface Selector {
  propertyName: string
  valueFormatter?: (value: string) => unknown
  prepend?: string
  append?: string
  delimiter?: string
  condition?: { propertyName: string, value: unknown }
}

const objectPropertyDisplayFormatter = (object, selectors: Selector[] = []) => {
  return selectors.map(({
    propertyName,
    valueFormatter,
    prepend = '',
    append = '',
    delimiter = '',
    condition
  }) => {
    let value = object?.[propertyName]
    if(value == null || (condition && object?.[condition?.propertyName] !== condition?.value)) {
      return ''
    }
    value = valueFormatter ? valueFormatter(object?.[propertyName]) : value
    return `${prepend}${value}${append}${delimiter}`
  }
  ).join('')
}

export const formatAccount = (account, selectors: Selector[] = [{ propertyName: 'id', delimiter: ' ' }, { propertyName: 'name' }]) => {
  return objectPropertyDisplayFormatter(account, selectors)
}

export const formatVat = (vat, selectors: Selector[] = [
  {
    propertyName: 'rate',
    valueFormatter: percentString,
    prepend: '(',
    append: ')',
    delimiter: ' '
  },
  {
    propertyName: 'name',
    condition: { propertyName: 'isSystem', value: false }
  }
]) => {
  return objectPropertyDisplayFormatter(vat, selectors)
}

export const formatAccountAndVat = (account, vat, options: { accountSelectors?: Selector[], vatSelectors?: Selector[], delimiter?: string } = {}) => {
  const {
    accountSelectors,
    vatSelectors,
    delimiter = ' '
  } = options
  const accountString = formatAccount(account, accountSelectors)
  const vatString = formatVat(vat, vatSelectors)
  return `${accountString}${delimiter}${vatString}`
}

export const getSplitOvt = receiver => {
  const splitOvt = receiver?.ovt?.split('@') || []
  const eInvoiceAddressNumber = splitOvt[0] || null
  const operator = splitOvt[1] || null
  return { eInvoiceAddressNumber, operator }
}

export const parseSafeNumber = (value: NullableNumberOrString, fallbackValue = 0) => {
  if(value == null || value === '' || Number.isNaN(value)) {
    return fallbackValue
  }
  if(isString(value)) {
    const maybeNumber = value.replace(/,/, '.')
    const numberValue = round8decimals(maybeNumber)
    return Number.isNaN(numberValue) ? fallbackValue : numberValue
  }
  return round8decimals(value)
}

export const parseSafeNumberWithMaxValue = (value: NullableNumberOrString, maxValue: number) => {
  const safeNumber = parseSafeNumber(value)
  return safeNumber > maxValue ? maxValue : safeNumber
}

export const getBirthDateOfEmployee = (employee: PrivateEmployeeModel) => {
  if(employee.dateOfBirth) {
    return employee.dateOfBirth
  } else if(employee.personalIdentityCode) {
    return personalIdentityCodeToDate(employee.personalIdentityCode)
  }
  throw new Error('Työntekijällä ei ole syntymäpäivää')
}
export const removeDuplicates = <T,>(array: T[]): T[] => Array.from(new Set(array))

export const getEmployeeAge = (employee: PrivateEmployeeModel, dateToCalculate: string) => {
  const birthDate = getBirthDateOfEmployee(employee)
  return moment(dateToCalculate).diff(birthDate, 'years', false)
}

export const isDefined = <T>(value: T | null | undefined): value is NonNullable<T> => value != null
