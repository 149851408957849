import { Id } from 'react-toastify'

import { updateNotification } from '../../helpers/notificationHelpers'

export const updatePurchaseOrdersUpdatedNotification = (toastId: Id, created: number[], updated: number[]) => {
  updateNotification(toastId, {
    autoClose: 4000,
    render: (
      <>
        Ostotilauksia luotu {created.length} kpl<br />
        Ostotilauksia päivitetty {updated.length} kpl
      </>
    )
  })
}
